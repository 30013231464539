/**
 * Mobile Menu
 * this contains functionality specific to the mobile menu
 */
import { getCurrentIndex, getMenuLinkData, getNavLevel } from '../util/menuLinkUtil';
import { getVisibleFocusableElems } from '../../../_shared-components/getDomElems';

/**
 * Mobile Menu State
 * @type {{openSubMenus: Array, openButtons: Array}}
 * @param {array} openSubMenus: Array of menus recently opened
 * @param {array} openButtons: Array of parent buttons for these menus
 * @param {Number} currentLevel: Nav level depth of current mobile
 */
const menuState = {
  openSubMenus: [],
  openButtons: [],
  currentLevel: 1,
};

/**
 * Sets menu state on mobile slide menus and the
 * button that controls them
 * @param {object} button: the button that opens the slide menu
 * @param {object} menuHandled: the menu that's being opened / closed
 * @param {string|enum} state: ['open', 'closed']
 *        whether the menu is being set to the open or closed state
 */
const setSlideMenuState = (button, menuHandled, state) => {
  const ariaState = state === 'open' ? 'true' : 'false';
  button.setAttribute('aria-expanded', ariaState);
  if (state === 'open') {
    menuHandled.setAttribute('data-toggle', state);
  } else {
    menuHandled.removeAttribute('data-toggle');
  }
};

/**
 * Add or remove elements from menuState arrays, as well as increment/decrement menuState
 * "currentLevel" property.
 * @param button
 * @param menuHandled
 */
const updateMenuStateTracking = (button = null, menuHandled = null) => {
  if (button && menuHandled) {
    menuState.openSubMenus.push(menuHandled);
    menuState.openButtons.push(button);
    menuState.currentLevel += 1;
  } else {
    menuState.openSubMenus.pop();
    menuState.openButtons.pop();
    menuState.currentLevel -= 1;
  }
};

/**
 * Will add or remove a class from the navMenu only if the menu that is being opened or closed
 * is at level 2. I.e. the first level of menu entries (aka category links) is about to be hidden
 * or displayed.
 * @see {@link https://issues.rei.com/browse/NAV1-645 NAV1-645} for more information
 * @param {object} navMenu: parent element containing all menus for a subsite
 * @param {object} menuHandled: the menu that's being opened / closed
 * @param {string|enum} state: ['open', 'closed']
 */
const setFirstLevelVisibility = (navMenu, menuHandled, state) => {
  if (getNavLevel(menuHandled) === 2) {
    if (state === 'open') {
      navMenu.classList.add('hide-level-1');
    } else {
      navMenu.classList.remove('hide-level-1');
    }
  }
};

/**
 * Mobile menu sliders (internal)
 * @param {Event} e: click event
 * @param {object} subscriber: <button> or <a> node subscribed to event
 * @param {string} controlType: 'slide-open' or 'slide-closed' (back-button)
 * @param {string} menuName: name of menu to be opened or closed
 * @param {object} navMenu: parent element containing all menus for a subsite
 */
export const slideMenu = (e, subscriber, controlType, menuName, navMenu) => {
  const childMenu = navMenu.querySelector(`[data-menu-name=${menuName}]`);
  if (controlType === 'slide-open') {
    /* Prevents activating link when a submenu exists */
    e.preventDefault();
    setSlideMenuState(subscriber, childMenu, 'open');
    setFirstLevelVisibility(navMenu, childMenu, 'open');
    updateMenuStateTracking(subscriber, childMenu);
    const childBackButton = childMenu.querySelector('[data-control-type="slide-closed"]');
    /* move focus to the close button on that menu */
    childMenu.addEventListener('transitionend', () => {
      childBackButton.focus();
    }, { once: true });
  }
  if (controlType === 'slide-closed') {
    const parentButton = navMenu.querySelector(`[data-control-type="slide-open"][data-controls="${menuName}"]`);
    setSlideMenuState(parentButton, childMenu, 'closed');
    setFirstLevelVisibility(navMenu, childMenu, 'closed');
    updateMenuStateTracking();
    /* move focus back to the parent after transition */
    childMenu.addEventListener('transitionend', () => {
      parentButton.focus();
    }, { once: true });
  }
};

/**
 * Clears all subMenus when main menu is toggled closed. If open-close
 * button is clicked in quick succession - user may see open submenu
 * sliding closed. Can fix by speeding up closing transition.
 */
export const clearSubMenus = (navMenu) => {
  menuState.openSubMenus.forEach((el) => el.removeAttribute('data-toggle'));
  menuState.openButtons.forEach((el) => el.setAttribute('aria-expanded', 'false'));
  navMenu.classList.remove('hide-level-1');
  /* reset menuState */
  menuState.openSubMenus = [];
  menuState.openButtons = [];
  menuState.currentLevel = 1;
};

/**
 * Closes mobile menu, and waits until transition is complete before
 * allowing browser to scroll to location on current page. Edge-case that
 * cannot be handled via toggleMenu function, but contains similar code.
 * @param {HTMLButtonElement} backdrop
 * @param {HTMLBodyElement} body
 * @param {HTMLDivElement} navMenu
 * @param {HTMLButtonElement} openButton
 * @param {HTMLElement} gnav
 * @param {string} href
 */
export const closeToScroll = (backdrop, body, navMenu, openButton, gnav, href) => {
  gnav.setAttribute('data-toggle', 'closed');
  openButton.setAttribute('aria-expanded', 'false');
  clearSubMenus(navMenu);
  backdrop.setAttribute('data-toggle', 'hidden');
  backdrop.addEventListener('transitionend', () => {
    body.classList.remove('no-scroll');
    /* refresh reference to trigger scroll */
    window.location.href = href;
  }, { once: true });
};

/**
 * Iterates through all mobile-panels/mobile-cards (if any exist) and returns array of elements
 * that meet the requirements of the filterFn method.
 * @param {object} gnav: Node element to limit query to gnav section of DOM
 * @param {function} filterFn: filtering method that determines if element is included in array
 * @returns {[]}
 */
const getMobilePanelElems = (gnav, filterFn) => {
  const elemArr = [];
  [...gnav.querySelectorAll('[data-nav-js="mobile-panel"]')].forEach((mobileCard) => {
    elemArr.push(...filterFn(mobileCard));
  });
  return elemArr;
};

/**
 * Returns mobile-specific elements that must subscribe to event handler(s) on initialization.
 * @param {object} gnav: Node element to limit query to gnav section of DOM
 * @returns {*[]}
 */
export const getMobileMenuSubscribers = (gnav) => (
  [...gnav.querySelectorAll('.actions__item [data-ui="nav-level-1"]'), ...gnav.querySelectorAll('[data-nav-js="mobile-panel"]')]
);

/**
 * Used for keyboard interactions. Returns an array of DOM Elems that represent all
 * visible entries for the open mobile menu.
 * @param gnav: Node element to limit query to gnav section of DOM, defaults to global window
 *             document.
 * @returns {*[]}
 */
const getMenuEntries = (gnav = document) => {
  const { openSubMenus, currentLevel } = menuState;
  let menuEntries;
  if (currentLevel === 1) {
    menuEntries = [...gnav.querySelectorAll('li > [data-ui="nav-level-1"]'), ...getMobilePanelElems(gnav, getVisibleFocusableElems)];
  } else {
    const lastIndx = openSubMenus.length - 1;
    const subMenuId = openSubMenus[lastIndx].id;
    menuEntries = [...gnav.querySelectorAll(`#${subMenuId} [data-ui="nav-level-${currentLevel}"]`)];
  }
  return menuEntries;
};

/**
 * Helper method - if DOM element and event are not null, then will set focus and prevent default.
 * @param {object} elem
 * @param event
 */
const focusOnElem = (elem = null, event = null) => {
  if (elem && event) {
    elem.focus();
    event.preventDefault();
  }
};

/**
 * Tab: Limits tabbing to only visible links in an open menu. Tab on lastVisibleLink will
 * wrap focus back to the firstVisibleLink. Shift+tab on firstVisibleLink will move the
 * focus to the close button.
 * @param e
 * @param gnav
 * @param closeButton
 */
export const tabKeyDown = (e, gnav, closeButton) => {
  let elem;
  const { target, shiftKey } = e;
  const {
    firstVisibleLink,
    lastVisibleLink,
  } = getMenuLinkData(getMenuEntries(gnav));
  const closeBtnToOpenSubmenu = (target.isEqualNode(closeButton) && menuState.currentLevel > 1);
  if ((closeBtnToOpenSubmenu || target.isEqualNode(lastVisibleLink)) && !shiftKey) {
    elem = firstVisibleLink;
  } else if (target.isEqualNode(firstVisibleLink) && menuState.currentLevel > 1 && shiftKey) {
    elem = closeButton;
  }
  focusOnElem(elem, e);
};

/**
 * ArrowDown | ArrowRight: Moves focus to consecutive indices within visibleLinks array.
 * If starting focus is on the closeButton elem or on the lastVisibleLink, then the focus
 * will go to the firstVisibleLink.
 * @param e
 * @param gnav
 * @param closeButton
 */
export const arrowDownKeyDown = (e, gnav, closeButton) => {
  let elem;
  const { target } = e;
  const {
    visibleLinks,
    firstVisibleLink,
    lastVisibleLink,
  } = getMenuLinkData(getMenuEntries(gnav));
  const currentLinkIndex = getCurrentIndex(visibleLinks, target);
  /* if target is in the open menu, but not the lastVisibleLink */
  if (currentLinkIndex > -1 && currentLinkIndex < (visibleLinks.length - 1)) {
    elem = visibleLinks[currentLinkIndex + 1];
  } else if (target.isEqualNode(closeButton) || target.isEqualNode(lastVisibleLink)) {
    elem = firstVisibleLink;
  }
  focusOnElem(elem, e);
};

/**
 * ArrowUp | ArrowLeft: Moves focus to previous indices within visibleLinks array.
 * If starting focus is on the firstVisibleLink, then the focus will move to the
 * closeButton.
 * If starting focus is on closeButton then moves focus to lastVisibleLink.
 * @param e
 * @param gnav
 * @param closeButton
 */
export const arrowUpKeyDown = (e, gnav, closeButton) => {
  let elem;
  const { target } = e;
  const {
    visibleLinks,
    firstVisibleLink,
    lastVisibleLink,
  } = getMenuLinkData(getMenuEntries(gnav));
  const currentLinkIndex = getCurrentIndex(visibleLinks, target);
  /* if target in the open menu, but not the firstVisibleLink */
  if (currentLinkIndex > 0 && currentLinkIndex < visibleLinks.length) {
    elem = visibleLinks[currentLinkIndex - 1];
  } else if (target.isEqualNode(closeButton) || target.isEqualNode(firstVisibleLink)) {
    elem = target.isEqualNode(closeButton) ? lastVisibleLink : closeButton;
  }
  focusOnElem(elem, e);
};

/**
 * End or Page Down: Moves focus to the lastVisibleLink
 * @param e
 * @param gnav
 */
export const endKeyDown = (e, gnav) => {
  const {
    lastVisibleLink,
  } = getMenuLinkData(getMenuEntries(gnav));
  focusOnElem(lastVisibleLink, e);
};

/**
 * Home or Page Up: Moves focus to firstVisibleLink
 * @param e
 * @param gnav
 */
export const homeKeyDown = (e, gnav) => {
  const {
    firstVisibleLink,
  } = getMenuLinkData(getMenuEntries(gnav));
  focusOnElem(firstVisibleLink, e);
};

/**
 * Adjust the css "top" property of open mobile menu based on bottom of global nav. This prevents
 * open space between global nav and open menu even if other common-components are hidden.
 * @param gnav
 * @param navMenu
 */
export const setTopForOpenMenu = (gnav, navMenu) => {
  const { bottom } = gnav.getBoundingClientRect();
  const scrollOffset = window.scrollY;
  const { style } = navMenu || {};
  style.top = `${scrollOffset + bottom}px`;
};

/**
 * Sets initial aria states on mobile menu entry
 * @param {HTMLAnchorElement|HTMLButtonElement} target: target of focus event
 * @param {string} controls: name of menu being controlled
 */
export const setAriaStatesOnFocus = (target, controls) => {
  target.setAttribute('aria-controls', controls);
  target.setAttribute('aria-expanded', 'false');
};

/**
 * Call after baseHideMenu in menu.js when closing a mobile menu
 * @param {HTMLButtonElement} openButton
 */
export const hideMobileMenu = (openButton) => {
  openButton.focus({ preventScroll: true });
};

/**
 * Call after baseShowMenu in menu.js when showing a mobile menu
 * @param {HTMLElement} backdrop
 * @param {HTMLButtonElement} closeButton
 * @param {HTMLElement} body
 * @param {(int) => void} sets navMenu.scrollTop
 */
export const showMobileMenu = (backdrop, closeButton, body, setNavMenuScrollTop) => {
  // ESLint error to set property on param so had to pass in function to do it
  setNavMenuScrollTop(0);
  backdrop.setAttribute('data-toggle', 'visible');
  closeButton.focus({ preventScroll: true });
  body.classList.add('no-scroll');
};