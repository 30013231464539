/** Menu Link Util
 * Util module containing helper methods shared between the mobile and desktop menus.
 * Mainly related to managing arrays containing the visible links of an open menu.
 */

/**
 * Helper method used to find the index of a DOM element
 * @param elemArray: array of DOM elements to iterate through
 * @param elem: DOM element to look for
 * @returns {number} index or -1 if not found
 */
export const getCurrentIndex = (elemArray = [], elem = null) => (
  elem ? elemArray.findIndex((el) => elem.isEqualNode(el)) : -1
);

/**
 * Returns an object containing commonly used values needed when managing keyboard
 * interactions within an open menu.
 * @param visibleLinks
 * @returns {{firstVisibleLink: null, lastVisibleLink: null, visibleLinks: *[]}}
 */
export const getMenuLinkData = (visibleLinks = []) => {
  let firstVisibleLink = null;
  let lastVisibleLink = null;
  if (visibleLinks.length > 0) {
    [firstVisibleLink] = visibleLinks;
    lastVisibleLink = visibleLinks[visibleLinks.length - 1];
  }
  return {
    visibleLinks, firstVisibleLink, lastVisibleLink,
  };
};

/**
 * Returns numeric value of element's data-nav-level attribute, if it exists.
 * @param elem: DOM element to get nav-level for
 * @returns {number} nav-level for element or -1 if unknown
 */
export const getNavLevel = (elem = {}) => {
  const { dataset: { navLevel = '-1' } = {} } = elem;
  return !navLevel ? -1 : navLevel * 1;
};
