/**
 * Desktop Menu
 * this contains functionality specific to the desktop menu
 */
import { getCurrentIndex, getMenuLinkData, getNavLevel } from '../util/menuLinkUtil';

/**
 * If a NavigationNode is not a leaf, then it has child links to display
 * @param elem {HTMLLIElement|HTMLAnchorElement|HTMLButtonElement|HTMLSpanElement}
 * @returns {boolean}
 */
export const hasChildren = (elem) => {
  const { dataset: { isLeaf } = {} } = elem || {};
  return isLeaf === 'false' || isLeaf === false;
};

/**
 * Confirm category node has a menu that can be opened.
 * @param {HTMLLIElement} category: <li> node that may contain an
 * expandable submenu or is just a link.
 */
export const openMenu = (category) => {
  if (hasChildren(category)) {
    category.firstElementChild.setAttribute('aria-expanded', 'true');
    category.classList.add('is-open');
  }
};
/**
 * Grab all category <li> nodes that indicate a menu is open.
 * Then iterate through each node and reset classes and arias
 * to a closed state.
 * @param navMenu: selector taken from menu init.
 */
export const closeMenu = (navMenu) => {
  navMenu.querySelectorAll('.is-open').forEach((categoryItem) => {
    categoryItem.classList.remove('is-open');
    categoryItem.firstElementChild.setAttribute('aria-expanded', 'false');
  });
};
/**
 * Returns an array of <a> nodes for a given category.  If no <a> nodes
 * are found or the category is null - then returns an empty array.
 * Order of elements matter as result is used for keyboard interactions
 * @param {object} category: <li> node
 * @returns {*[]}
 */
const getMenuLinks = (category = null) => {
  const merchZoneLinksSelector = '.gnav-submenu-wrapper .extra a.subsite-navigation__link';
  const menuLinksSelector = '.gnav-submenu-wrapper .subsite-navigation__item > a.subsite-navigation__link';
  const megaMenuHeaderLinkSelector = '.gnav-submenu-wrapper__header-link';
  return [...category.querySelectorAll(`
    ${menuLinksSelector},
    ${merchZoneLinksSelector},
    ${megaMenuHeaderLinkSelector}
  `)];
};

/**
 * Returns true if el node is an anchor and contains a data-nav-level
 * attribute greater than 1 (i.e. is not a top-level category link).
 * @param {object} el: DOM node to check
 * @returns {boolean|boolean}
 */
export const isMenuLink = (el) => (
  el.tagName === 'A' && (getNavLevel(el) > 1)
);

/**
 * Returns true if element param is a top level category button or link
 * that has displayable children.
 * @param {HTMLAnchorElement|HTMLButtonElement|HTMLLIElement} el
 * @returns {boolean}
 */
export const isMenuRoot = (el) => (
  (getNavLevel(el) === 1) && hasChildren(el)
);

/**
 * ESCAPE: Closes menu and returns focus to top level category
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const escapeKeyDown = (e, menuState, toggleMenu) => {
  const { target } = e;
  const { isOpen, activeCategory } = menuState;
  if (isOpen) {
    toggleMenu();
  }
  /* Move focus to category if starting focus was on
   * link in open menu */
  if (isMenuLink(target)) {
    const categoryAnchor = activeCategory.firstElementChild;
    categoryAnchor.focus();
  }
};

/**
 * ArrowDown: If starting focus is on a category then opens submenu & moves
 * focus to the first submenu link. If starting focus is on a submenu link,
 * then moves focus to the next submenu link (wrapping from the last to first)
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const arrowDownKeyDown = (e, menuState, toggleMenu) => {
  const { target } = e;
  const { isOpen, activeCategory } = menuState;
  let rel;
  if (!isMenuLink(target)) {
    if (isOpen) {
      toggleMenu();
    }
    /* toggle shared menu state */
    toggleMenu();
    openMenu(activeCategory);
  }
  const {
    visibleLinks,
    firstVisibleLink,
    lastVisibleLink,
  } = getMenuLinkData(getMenuLinks(activeCategory));
  const currentLinkIndex = getCurrentIndex(visibleLinks, target);

  /* If target is a category link or the lastVisibleLink focus on the FIRST link in menu */
  if (!isMenuLink(target) || target === lastVisibleLink) {
    rel = firstVisibleLink;
  } else if (currentLinkIndex > -1 && currentLinkIndex < (visibleLinks.length - 1)) {
    rel = visibleLinks[currentLinkIndex + 1];
  }

  if (rel) {
    rel.focus();
    e.preventDefault();
  }
};

/**
 * ArrowUp: If focus is on a link within open menu then move focus to previous link / link
 * above starting focus. If starting at the the top item, move focus to the last (bottom)
 * link. If focus is on a Category with a submenu then opens the submenu and places focus
 * on the LAST link in submenu
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const arrowUpKeyDown = (e, menuState, toggleMenu) => {
  const { target } = e;
  const { isOpen, activeCategory } = menuState;
  let rel;
  if (!isMenuLink(target)) {
    /* close any open menus */
    if (isOpen) {
      toggleMenu();
    }
    toggleMenu();
    openMenu(activeCategory);
  }
  const {
    visibleLinks,
    firstVisibleLink,
    lastVisibleLink,
  } = getMenuLinkData(getMenuLinks(activeCategory));
  const currentLinkIndex = getCurrentIndex(visibleLinks, target);
  /* If target is on a top-level tab or the firstVisibleLink
   * then focus on the LAST link in menu */
  if (!isMenuLink(target) || target === firstVisibleLink) {
    rel = lastVisibleLink;
  } else if (currentLinkIndex > 0) {
    rel = visibleLinks[currentLinkIndex - 1];
  }

  if (rel) {
    rel.focus();
    e.preventDefault();
  }
};
/**
 * ArrowRight: If starting focus is on a link within an open menu then close the current menu,
 * move focus to category on the right (or wrap around to first/left-most category), and open
 * menu.  Do not move focus into the newly opened menu.
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const arrowRightKeyDown = (e, menuState, toggleMenu) => {
  const { isOpen, activeCategory, subsiteCategories } = menuState;
  let openNextTarget = false;
  if (isOpen) {
    openNextTarget = true;
    /* close current menu */
    toggleMenu();
  }

  const currentCategoryIndex = getCurrentIndex(subsiteCategories, activeCategory);
  /* Go to next category - if on the last category wrap around to first */
  const nextCategoryIndex = (currentCategoryIndex === subsiteCategories.length - 1)
    ? 0 : currentCategoryIndex + 1;
  /* Focus on next top-level-tab and open mega-menu (sans inner focus) */
  const nextTarget = subsiteCategories[nextCategoryIndex];

  if (openNextTarget) {
    toggleMenu();
    openMenu(nextTarget);
  }
  nextTarget.firstElementChild.focus();
  e.preventDefault();
};

/**
 * ArrowLeft: If focus is in submenu of an item in a menubar (aka category) then
 * close submenu, move focus to category on the left (or wrap to right-most category),
 * and open submenu of that category without moving focus into newly opened submenu
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const arrowLeftKeyDown = (e, menuState, toggleMenu) => {
  const { isOpen, activeCategory, subsiteCategories } = menuState;
  let openNextTarget = false;
  if (isOpen) {
    openNextTarget = true;
    /* close current menu */
    toggleMenu();
  }
  const currentCategoryIndex = getCurrentIndex(subsiteCategories, activeCategory);
  /* Go to previous tab - if on the first tab go to last */
  const nextCategoryIndex = (currentCategoryIndex === 0)
    ? subsiteCategories.length - 1 : currentCategoryIndex - 1;
  /* Focus on next top-level-tab and open mega-menu (sans inner focus) */
  const nextTarget = subsiteCategories[nextCategoryIndex];

  if (openNextTarget) {
    toggleMenu();
    openMenu(nextTarget);
  }
  nextTarget.firstElementChild.focus({ preventScroll: true });
  e.preventDefault();
};

/**
 * Home or Page Up: If menu is open and link has focus - move focus to the first item in the
 * current menu. If menu is closed or open but not in focus then move focus to
 * first Category in the gnav (open menu if original target was open)
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const homeKeyDown = (e, menuState, toggleMenu) => {
  let openNextTarget = false;
  let nextTarget;
  const { target } = e;
  const { isOpen, activeCategory, subsiteCategories } = menuState;
  if (isOpen) {
    const { firstVisibleLink } = getMenuLinkData(getMenuLinks(activeCategory));
    if (isMenuLink(target)) {
      /* Focus on first visible link */
      nextTarget = firstVisibleLink;
    } else {
      openNextTarget = true;
      /* close current menu */
      toggleMenu();
      /* Focus on first top-level-tab and open mega-menu (sans inner focus) */
      [nextTarget] = subsiteCategories;
    }
  } else {
    /* Focus on first category */
    [nextTarget] = subsiteCategories;
  }
  if (openNextTarget) {
    toggleMenu();
    openMenu(nextTarget);
  }
  if (nextTarget.classList.contains('subsite-category')) {
    nextTarget.firstElementChild.focus();
  } else {
    nextTarget.focus();
  }
  e.preventDefault();
};

/**
 * End or Page Down: If menu is open and link has focus - move focus to the last item
 * in the current menu. If menu is open without link focus or is closed -
 * move focus to last item in the gnav
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const endKeyDown = (e, menuState, toggleMenu) => {
  const { isOpen, activeCategory, subsiteCategories } = menuState;
  let openNextTarget = false;
  let nextTarget;
  const { target } = e;
  const { lastVisibleLink } = getMenuLinkData(getMenuLinks(activeCategory));
  if (isOpen) {
    if (isMenuLink(target)) {
      /* Focus on last visible link */
      nextTarget = lastVisibleLink;
    } else {
      openNextTarget = true;
      /* close current menu */
      toggleMenu();
      /* Focus on last category and open menu (sans inner focus) */
      nextTarget = subsiteCategories[subsiteCategories.length - 1];
    }
  } else {
    /* Focus on last category */
    nextTarget = subsiteCategories[subsiteCategories.length - 1];
  }

  if (openNextTarget) {
    toggleMenu();
    openMenu(nextTarget);
  }

  if (nextTarget.classList.contains('subsite-category')) {
    nextTarget.firstElementChild.focus();
  } else {
    nextTarget.focus();
  }
  e.preventDefault();
};
/**
 * Solves edge case with tabbing and open menus:
 * If focus moves to different category - any open menus should close
 * @param e
 * @param {object} menuState
 * @param {function} toggleMenu: function which toggles the menu state
 *                    shared in both desktop and mobile platforms
 */
export const tabKeyDown = (e, menuState, toggleMenu) => {
  const { target, shiftKey } = e;
  const { isOpen, activeCategory } = menuState;
  if (isOpen) {
    /* If shift+tabbing away from category with open menu - close menu */
    if (!isMenuLink(target) && shiftKey) {
      toggleMenu();
    }
    const { lastVisibleLink } = getMenuLinkData(getMenuLinks(activeCategory));
    /* If tabbing away from last link on open menu - close menu */
    if (target === lastVisibleLink && !shiftKey) {
      toggleMenu();
    }
  }
};
/**
 * Tracking function for hovers on the desktop menu NAV1-1337
 * Adds map so each top level is only tracked once
 * @param target
 */
const hoverTrackMap = {};
export const handleHoverTrack = (target) => {
  if (window && window.metrics && isMenuRoot(target)) {
    const targetUi = target.dataset?.ui;

    // if we already tracked this item, stop early
    if (hoverTrackMap?.[targetUi]) {
      return;
    }

    const menu = target.querySelector('.gnav-submenu-wrapper');

    const track = () => {
      // check again since multiple transition will trigger the "transitionend" event
      if (hoverTrackMap?.[targetUi]) {
        return;
      }

      hoverTrackMap[targetUi] = true;

      // mouseenter works on the LI so get the button
      const button = target.querySelector('.subsite-navigation__link[data-ui="nav-level-1"]');
      window.metrics.link({ linkName: button.dataset.analyticsId });

      menu.removeEventListener('transitionend', track);
    };

    // only send/set tracking once transition is done and the menu is visible
    menu.addEventListener('transitionend', track);
  }
};
