import getScriptData from '../util/getScriptData';
import navEvent from '../util/navEvent';
import { urlHelper, CART_PING_TARGET } from '../util/urlUtil';
import { useCartCounterFetch } from './cartCounterFetcher';

const reflow = (el) => el.offsetParent;

export default ({
  window: { document },
  selectors,
}) => {
  const maxCartDisplayCount = 99;

  let cancelUpdate;
  let config;
  const cart = document.querySelector(selectors.cart); // Init cart

  /**
   * Checks if string or boolean are "truthy"
   * NOTE: Not the basic definition of truthy, if string is 'true' case insensitive
   *       or boolean is true
   * @param {String|Boolean} value
   * @returns {Boolean}
   */
  const isConfigPropertyTruthy = (value) => (typeof value === 'string' ? value.toLocaleLowerCase() === 'true' : value === true);

  /**
   * Adds a ?_={new Date} to a url
   * @returns {String}
   */
  const getCartCountPingUrl = () => urlHelper.appendPingDate(config.cartCountTarget);

  const fetchUpdate = (err, cb) => (
    useCartCounterFetch(getCartCountPingUrl(), err, cb)
  );

  /**
   * Return true if count is greater than the max number that should be displayed
   * @param {Number} count
   * @returns
   */
  const isCountGreaterThanMaxDisplayCount = (count) => count > maxCartDisplayCount;

  /**
   * Build label string for display of
   * Example: display count unless greater than the max number to display else display '{max}+'
   * @param {Number} count
   * @return
   */
  const buildCountLabel = (count) => (isCountGreaterThanMaxDisplayCount(count) ? `${maxCartDisplayCount}+` : `${count}`);

  /**
   * Updates count on an Element. Anything about 999 will show as 999+ for
   * desktop and about 99 will show as 99+ for mobile
   * @param {number|string} count
   */
  const updateCount = (count) => {
    /* if number is a string and/or contains decimals, make a whole number. */
    const numericCount = Number(count) ? Math.trunc(count * 1) : -1;
    // Exit if no Element found
    if (!cart) {
      return;
    }
    // Remove count attribute & update aria label if count is less than zero
    if (numericCount <= 0) {
      cart.removeAttribute('data-count-label');
      cart.removeAttribute('data-count');
      cart.setAttribute('aria-label', 'Shopping cart is empty');
    } else {
      // Update count, label, and aria-label
      cart.dataset.count = `${numericCount}`;
      // Display of item count
      cart.dataset.countLabel = buildCountLabel(numericCount);
      cart.setAttribute('aria-label', `Shopping cart contains ${numericCount} ${numericCount === 1 ? 'item' : 'items'}`);
    }
    reflow(cart);
  };

  const onSuccess = (count) => {
    updateCount(count);
  };

  const onError = (/* error */) => {
    // TODO what to do here?
  };

  /**
   * Handle CART_COUNT_EVENT event being fired
   * to update cart count
   * @param {Event} evt
   */
  const cartCountChangeHandler = (evt) => {
    cancelUpdate();
    if (evt.detail != null) {
      /* validates detail and updates cartCount. Does not fetch data / ping endpoint */
      onSuccess(evt.detail);
    } else {
      /* Fetches cart / pings endpoint if evt or evt.detail is null, undefined or missing */
      cancelUpdate = fetchUpdate(onError, onSuccess);
    }
  };

  /**
   * Replacement for adding event listeners to handle cross browser support in one location
   * TODO: Should move into its own global util, so we can update app if we find other
   *       cross platform issues
   * @param {window|document|Element} target
   * @param {String} event
   * @param {() => {}} handler
   */
  const addEventListener = (target, event, handler) => {
    if (target.addEventListener) {
      target.addEventListener(event, handler);
    } else if (target.addListener) {
      /* Safari Support */
      target.addListener(handler);
    } else {
      console.warn(`unable to add listener to target of type ${target.constructor.name}`);
    }
  };

  const addCartCountChangeListener = () => {
    addEventListener(document, navEvent.CART_COUNT_CHANGE_EVENT, cartCountChangeHandler);
  };

  const addListeners = () => {
    addCartCountChangeListener();
  };

  // Pull gnav-data config and assign config required for the component
  const initConfig = () => {
    const {
      pingCartOnLoad = false,
      cartCountTarget = CART_PING_TARGET,
    } = getScriptData('gnav-data') || {};

    config = {
      pingCartOnLoad,
      cartCountTarget,
    };
  };

  const initCounter = () => {
    initConfig();
    addListeners();

    /* Don't ping cart endpoint if cart is not configured */
    if (cart && isConfigPropertyTruthy(config.pingCartOnLoad)) {
      cancelUpdate = fetchUpdate(onError, onSuccess);
    }
  };

  initCounter();
};
