/** Global Navigation
 * Base component for global navigation. Contains foundational functionality for desktop and
 * mobile menu, initialization of click analytics, lazy loader initialization (for images in
 * mega-menus), and the cart counter. Cart counter initializes conditionally depending on
 * site-level configuration.
 */
import menuInit from '../components/menu';
import cartCounter from '../components/cartCounter';
import analyticsInit from '../../../_shared-components/navigationMetrics';

export function app(win) {
  /* init the mobile menu toggle */
  menuInit({
    openButton: '[data-js="open-menu"]',
    gnav: '[data-js="gnav"]',
    backdrop: '[data-js="backdrop"]',
    closeButton: '[data-js="close-menu"]',
    navMenu: '[data-js="subsite-navigation"]',
  });
  cartCounter({
    window: win,
    selectors: {
      cart: '[data-js="cart-button"]',
    },
  });
  analyticsInit('[data-js="gnav"]');
  return win;
}

export function main(win = window) {
  if (win.document.readyState === 'loading') {
    // Loading hasn't finished yet
    win.document.addEventListener('DOMContentLoaded', app.bind(null, win));
  } else {
    // `DOMContentLoaded` has already fired
    app(win);
  }
}
