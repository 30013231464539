/**
 * Menu js
 * Contains the main handlers for the sub-site navigation
 * menus. Functions specific to mobile or desktop
 * belong in those files.
 */
import { isDesktop, isMobile } from '../util/getScreenSize';
import { getDomElems } from '../../../_shared-components/getDomElems';
import { getKeyCode, code, keyCode } from '../../../_shared-components/keyCode';
import getClickSubscriber from '../util/eventDelegationUtil';
import * as mobile from './mobile-menu';
import * as desktop from './desktop-menu';
import { eventQuery } from '../util/mediaQuery';

/**
 * Holds menu state elements (as needed)
 * @type {{isOpen: boolean, activeCategory: null, subsiteCategories: []}}
 * @param {boolean} isOpen: indicates whether a category menu is open
 * @param {boolean} openOnClick: indicates if menu should open on click
 * @param {object} activeCategory: <li> node that is either in focus or has an open menu
 * @param {array} subsiteCategories: Array of <li> nodes that represent a subsite's top
 * level categories
 */
const menu = {
  isOpen: false,
  activeCategory: null,
  subsiteCategories: [],
  openOnClick: false,
};
/* Added for testing - resets state on init */
const setInitMenuState = () => {
  menu.isOpen = false;
  menu.activeCategory = null;
  menu.subsiteCategories = [];
};

/**
 * Sets menu state for activeCategory
 * @param {Element} tab default null
 */
const setActiveCategory = (tab = null) => {
  menu.activeCategory = tab;
};

/**
 * Default behavior for desktop and mobile to hide menu
 */
const baseHideMenu = () => {
  const {
    gnav, openButton, navMenu, body, backdrop,
  } = menu;
  gnav.setAttribute('data-toggle', 'closed');
  openButton.setAttribute('aria-expanded', 'false');
  menu.isOpen = false;
  /* keep closed menu states in sync for mobile & desktop */
  mobile.clearSubMenus(navMenu);
  body.classList.remove('no-scroll');
  backdrop.setAttribute('data-toggle', 'hidden');
  desktop.closeMenu(navMenu);
};

/**
 * Default behavior for desktop and mobile to show menu
 */
const baseShowMenu = () => {
  const {
    gnav, openButton,
  } = menu;
  gnav.setAttribute('data-toggle', 'open');
  openButton.setAttribute('aria-expanded', 'true');
  menu.isOpen = true;
};

/**
 * Close navigation menu
 */
const closeMenu = () => {
  const {
    openButton,
  } = menu;

  baseHideMenu();
  if (isMobile()) {
    mobile.hideMobileMenu(openButton);
  }
};

/**
 * Open navigation menu
 */
const openMenu = () => {
  const {
    navMenu, gnav, backdrop, closeButton, body,
  } = menu;

  if (isMobile()) {
    mobile.setTopForOpenMenu(gnav, navMenu);
    const setNavMenuScrollTop = (position) => {
      navMenu.scrollTop = position;
    };
    mobile.showMobileMenu(backdrop, closeButton, body, setNavMenuScrollTop);
  }
  // call base function after isMobile block
  // mobile.setTopForOpenMenu performs a style read,
  // so it should occur before all style writes
  // https://web.dev/articles/avoid-large-complex-layouts-and-layout-thrashing#avoid_forced_synchronous_layouts
  baseShowMenu();
};

/**
 * Toggle navigation menu for mobile or desktop open / closed
 */
const toggleMenu = () => {
  if (menu.isOpen) {
    closeMenu();
  } else {
    openMenu();
  }
};

const hasOpenMenus = () => menu.navMenu.querySelectorAll('.is-open').length;

/**
 * Handle all navigation menu clicks
 * @param e: event
 */
const clickHandler = (e) => {
  const subscriber = getClickSubscriber(e);
  if (!subscriber) {
    return;
  }
  const { controlType, controls } = subscriber.dataset;
  const {
    navMenu, isOpen, backdrop, body, openButton, gnav,
  } = menu;
  /* mobile only */
  if (isMobile()) {
    const { location } = window;
    const { pathname, hash, href } = subscriber;
    /* Close menu and scroll if clicked link is located within current page. */
    if ((location.pathname === pathname && hash) && isOpen) {
      menu.isOpen = false;
      mobile.closeToScroll(backdrop, body, navMenu, openButton, gnav, href);
      return;
    }
    if (controlType === 'toggle-menu') {
      toggleMenu();
      return;
    }

    /* add slider functionality */
    if (controls !== undefined) {
      mobile.slideMenu(e, subscriber, controlType, controls, navMenu);
    }
  }

  /* desktop */
  if (isDesktop() && desktop.isMenuRoot(subscriber)) {
    /* do not activate link */
    e.preventDefault();

    const isCurrentMenuOpen = subscriber.parentElement.classList.contains('is-open');

    if (hasOpenMenus()) {
      closeMenu();
    }

    // current menu is open and its clicked again
    if (isCurrentMenuOpen) {
      return;
    }

    /* Menu is in closed state and should be opened. */
    openMenu();
    desktop.openMenu(subscriber.parentElement);
    setActiveCategory(subscriber.parentElement);
  }
};

/**
 * Handle all navigation focus events
 * @param e: focus Event
 */
const focusHandler = (e) => {
  /* adding aria-states dynamically */
  const { target } = e;
  const { dataset: data } = target || {};
  if (!data || !target) {
    return;
  }
  const { navLevel, controls, controlType } = data;
  /* desktop focus handling - state management only */
  if (isDesktop() && navLevel === '1') {
    setActiveCategory(target.parentElement);
  }

  /* set initial aria states */
  if (isMobile()) {
    const { openButton } = menu;
    if (target === openButton || controlType === 'slide-open') {
      mobile.setAriaStatesOnFocus(target, controls);
    }
  }
};

/**
 * Handle all navigation blur events
 * @param e
 */
const blurHandler = (e) => {
  const { navMenu } = menu;
  const { relatedTarget } = e;
  const isOpen = hasOpenMenus();
  if (isDesktop()) {
    /* If focus is moving outside of navigation - make sure menus are closed */
    if (((relatedTarget && !navMenu.contains(relatedTarget))) && isOpen) {
      closeMenu();
    }
  }
};

/**
 * Checks activeElement parent contains expected subsite-category class
 * @returns {Boolean}
 */
const isActiveElementChildOfSubsiteCategory = () => document.activeElement && document.activeElement.parentElement.classList.contains('subsite-category');

/**
 * Checks activeElement contains expected subsite-navigation__link class
 * @returns {Boolean}
 */
const isActiveElementNavigationLink = () => document.activeElement && document.activeElement.classList.contains('subsite-navigation__link');

/**
 * Checks activeElement contains expected subsite-navigation__link class
 * @returns {Boolean}
 */
const isActiveElementCategory = () => isActiveElementChildOfSubsiteCategory()
  && isActiveElementNavigationLink();

/**
 * Blur active element if determined to be a category element
 * to prevent undesirable styles
 */
const clearCategoryFocus = () => {
  if (isActiveElementCategory()) {
    document.activeElement.blur();
  }
};

/**
 * Target will always be li.subsite-category
 * @param e
 */
const mouseLeave = (e) => {
  const { target } = e;
  /* desktop focus handling */
  if (isDesktop()) {
    toggleMenu();
    target.removeEventListener('mouseleave', mouseLeave, false);
  }
};

const mouseEnter = (e) => {
  const { target } = e;
  /* desktop focus handling */
  if (isDesktop()) {
    // Remove focus to fix styles when selecting a new category
    clearCategoryFocus();
    if (hasOpenMenus()) {
      closeMenu();
    }
    toggleMenu();

    // add this before the open function so we have the transitionend
    // event listener added before it starts
    desktop.handleHoverTrack(target);

    desktop.openMenu(target);
    target.addEventListener('mouseleave', mouseLeave, false);
    setActiveCategory(target);
  }
};

const handleDocumentClick = (e) => {
  const { navMenu } = menu;

  if (hasOpenMenus() && !navMenu.contains(e.target)) {
    closeMenu();
  }
};

const handleEscape = (e) => {
  const isOpen = hasOpenMenus();
  const currentKey = getKeyCode(e);

  if (currentKey === keyCode.ESCAPE || currentKey === code.ESCAPE) {
    if (isDesktop()) {
      desktop.escapeKeyDown(e, { ...menu, isOpen }, closeMenu);
    } else if (isMobile() && menu.isOpen) {
      closeMenu();
    }
  }
};

const keyDown = (e) => {
  const { closeButton, gnav } = menu;
  const currentKey = getKeyCode(e);

  switch (currentKey) {
    case keyCode.ARROW_DOWN:
    case code.ARROW_DOWN:
      if (isDesktop()) {
        desktop.arrowDownKeyDown(e, menu, toggleMenu);
      } else {
        mobile.arrowDownKeyDown(e, gnav, closeButton);
      }
      break;
    case keyCode.ARROW_UP:
    case code.ARROW_UP:
      if (isDesktop()) {
        desktop.arrowUpKeyDown(e, menu, toggleMenu);
      } else {
        mobile.arrowUpKeyDown(e, gnav, closeButton);
      }
      break;
    case keyCode.ARROW_RIGHT:
    case code.ARROW_RIGHT:
      if (isDesktop()) {
        desktop.arrowRightKeyDown(e, menu, toggleMenu);
      } else {
        mobile.arrowDownKeyDown(e, gnav, closeButton);
      }
      break;
    case keyCode.ARROW_LEFT:
    case code.ARROW_LEFT:
      if (isDesktop()) {
        desktop.arrowLeftKeyDown(e, menu, toggleMenu);
      } else {
        mobile.arrowUpKeyDown(e, gnav, closeButton);
      }
      break;
    case keyCode.HOME:
    case keyCode.PAGE_UP:
    case code.HOME:
    case code.PAGE_UP:
      if (isDesktop()) {
        desktop.homeKeyDown(e, menu, toggleMenu);
      } else {
        mobile.homeKeyDown(e, gnav);
      }
      break;
    case keyCode.END:
    case keyCode.PAGE_DOWN:
    case code.END:
    case code.PAGE_DOWN:
      if (isDesktop()) {
        desktop.endKeyDown(e, menu, toggleMenu);
      } else {
        mobile.endKeyDown(e, gnav);
      }
      break;
    case keyCode.TAB:
    case code.TAB:
      if (isDesktop()) {
        /* edge case with tab and open menus:
         * If focus moves to different category - any open menus should close */
        desktop.tabKeyDown(e, menu, toggleMenu);
      } else {
        mobile.tabKeyDown(e, gnav, closeButton);
      }
      break;
    default:
      break;
  }
};

/**
 * Adds mobile menu settings if a menu is open while user switches platforms
 * on browser resize
 */
const syncMenuStateOnPlatformChange = () => {
  const {
    gnav, isOpen, navMenu, body,
  } = menu;
  if (isOpen) {
    mobile.setTopForOpenMenu(gnav, navMenu);
    navMenu.scrollTop = 0;
    body.classList.add('no-scroll');
  }
};
/**
 * Listen to media queries where the platform changes (ex. mobile to desktop),
 * and where the mobile masthead height changes (impacts an open mobile menu).
 * Includes support for Safari (addListener)
 */
const addMediaQueryListeners = () => {
  const { gnav, navMenu } = menu;
  /* $cdr-breakpoint-md = 992px */
  const platformSwitch = window.matchMedia(eventQuery.PLATFORM_SWITCH);
  /* $cdr-breakpoint-sm = 768px */
  const dynamicMobileMastheadHeight = window.matchMedia(eventQuery.MOBILE_MASTHEAD_HEIGHT_SWITCH);
  platformSwitch.addEventListener('change', syncMenuStateOnPlatformChange);
  dynamicMobileMastheadHeight.addEventListener(
    'change',
    mobile.setTopForOpenMenu.bind(null, gnav, navMenu)
  );
};

/**
 * Init
 * @param selectors: selectors for dom elems that must
 * be present in order for menus to function
 */
const init = (selectors) => {
  const navElems = getDomElems(selectors);
  /* Exit if one (or more) selectors are not found */
  if (navElems === null) {
    return;
  }
  setInitMenuState();
  /* Add body to prevent scroll on open mobile menu */
  const bodyElem = getDomElems({ body: 'body' });
  Object.assign(menu, navElems, bodyElem);
  const {
    gnav, closeButton, openButton, navMenu, backdrop,
  } = navElems;

  menu.openOnClick = navMenu.dataset?.openOnClick === 'true';

  /* add click handlers */
  [navMenu, openButton, closeButton, backdrop].forEach((elem) => {
    elem.addEventListener('click', clickHandler);
  });
  const categories = navMenu.getElementsByClassName('subsite-category');
  [...categories].forEach((category) => {
    // don't add mouse hover if using click only
    if (!menu.openOnClick) {
      category.addEventListener('mouseenter', mouseEnter, false);
    }

    category.addEventListener('keydown', keyDown);
  });
  menu.subsiteCategories = [...categories];
  /* Set up handlers for elements that only exist in mobile */
  [closeButton, ...mobile.getMobileMenuSubscribers(gnav)].forEach((mobileElem) => {
    mobileElem.addEventListener('keydown', keyDown);
  });
  /* add focus handlers */
  gnav.addEventListener('focusin', focusHandler);
  gnav.addEventListener('focusout', blurHandler);
  /* listen for a couple media queries */
  addMediaQueryListeners();

  document.addEventListener('keydown', handleEscape);

  if (isDesktop()) {
    document.addEventListener('click', handleDocumentClick);
  }
};

export default init;
