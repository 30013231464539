/**
 * Constants containing media queries when actions occur in the application
 * Example:
 * // Attach listener when media query happens
 * const event = window.matchMedia(eventQuery.PLATFORM_SWITCH);
 * if (event.addEventListener) {
 *   event.addEventListener('change', () => {});
 * } else if (event.addEventListener) {
 *   // Safari Support
 *   event.addListener(() => {});
 * }
 */
const eventQuery = {
  /*
   * $cdr-breakpoint-md = 992px
   * Media query when platform changes between mobile and desktop
  */
  PLATFORM_SWITCH: '(min-width: 992px)',
  /*
   * $cdr-breakpoint-sm = 768px
   * Media query when masthead height changes
  */
  MOBILE_MASTHEAD_HEIGHT_SWITCH: '(min-width: 768px)',
};

/**
 * Constants containing media queries for checking for matches
 * Example:
 * // Check if matches the media query
 * window.matchMedia(matchQuery.REDUCED_MOTION).matches
 */
const matchQuery = {
  /*
   * Media query if reduced motion is set
  */
  REDUCED_MOTION: '(prefers-reduced-motion)',
};

export {
  eventQuery,
};

export default matchQuery;
