// Static urls and targets to append on urls
const CART_PING_TARGET = 'https://www.rei.com/rest/cart/count.json';

// Contains all static urls and targets
const urls = {
  CART_PING_TARGET,
};

// Helper contains useful functions for modifying urls
const urlHelper = {
  /**
   * Adds _ property containing a new data to the end of a url
   * currently used to ping cart
   * @param {string} url
   * @returns
   */
  appendPingDate: (url) => `${url}?_=${+new Date()}`,
};

export {
  urlHelper,
  CART_PING_TARGET,
};

export default urls;
