/** Event Delegation Util
 * Util module containing helper methods related to returning the preferred target
 * element when using either event delegation or individual handlers.
 */

const isClickSubscriber = (elem = {}) => (elem.tagName === 'A' || elem.tagName === 'BUTTON');

/**
 * Recursive method that checks if elem param is an anchor or button, if not
 * then calls itself with the parentElement. Base cases are:
 *  - the elem param is falsey
 *  - the elem param is the same as the currentTarget (subscribing element)
 *  - the elem param is an anchor or button element
 * @param elem
 * @param currentTarget
 * @returns {*}
 */
const traverseToFindClickSubscriber = (elem, currentTarget) => (
  (!elem || isClickSubscriber(elem) || elem === currentTarget)
    ? elem
    : traverseToFindClickSubscriber(elem.parentElement, currentTarget)
);

/**
 * Will return event.currentTarget if it is an anchor or button. Otherwise
 * uses event.target as starting point to find anchor or button element.
 * @param event
 * @returns {HTMLElement|*} a falsey value or an HTMLElement
 */
const getClickSubscriber = (event) => {
  const { target, currentTarget } = event || {};
  return isClickSubscriber(currentTarget)
    ? currentTarget : traverseToFindClickSubscriber(target, currentTarget);
};

export default getClickSubscriber;
