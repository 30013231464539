export const useCartCounterFetch = (url, err, cb) => {
  const req = new XMLHttpRequest();
  req.open('GET', url);
  req.withCredentials = true;
  req.onreadystatechange = () => {
    if (req.readyState !== 4) {
      return;
    }
    switch (req.status) {
      case 401:
        cb(0);
        return;
      case 200:
        try {
          const data = JSON.parse(req.responseText);
          cb(data);
        } catch (e) {
          err(e);
        }
        return;
      default:
        err(new Error('Bad response'));
    }
  };
  req.send();
  return () => req.abort();
};

export default useCartCounterFetch;